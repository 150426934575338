import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData, getCampaignTypesView, getCampaignTypes, getUserTypesAccount, getIndistries, getPaineis, getLanguages } from '../../../actions/campaignActions'
import account from '../../Account/FormAccount/account';
import { translate } from '../../../components/Translate'

export class Campaign extends Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);

	}

	onChange(e) {
		let data = this.props.campaignData;
		data[e.target.name] = e.target.value;
		this.props.changeCampaignData(data);
		this.props.updateMyState()
		this.setState({ update: true })

	}
	async componentDidMount() {
		await this.props.getCampaignTypesView();
		await this.props.getCampaignTypes();
		await this.props.getIndistries();
		await this.props.getUserTypesAccount();
		await this.props.getPaineis();
		await this.props.getLanguages();
		this.setState({ update: true });
	}

	escolhaDaCampanha = async (index, item) => {
		let data = this.props.campaignData;

		// if(data.contacts != ""){
		await this._removeContato(index, item);
		this.setState({ update: true });

		// }else{
		//     await this._removeContato(index,item);           
		//     this.setState({ update: true });
		// }
		this.props.updateMyState()

	}

	_removeContato = index => {
		let data = this.props.campaignData
		//this.state.users.push(index)
		//console.log(index)
		//this.state.users.push(data.contacts[index])
		// this.state.users.push(index); 
		// this.props.update(data.contacts)       
		data.contacts = [];
		this.props.changeCampaignData(data);
		this.setState({ update: true });

		//alert(data.contacts);
	}

	onChangeFile(e) {

		let data = this.props.campaignData;
		data[e.target.name] = e.target.files[0];
		this.setState({ update: true })
	}

	render() {
		const { campaignData } = this.props;
		const { accounts, paineis } = this.props;
		console.log(paineis);

		return (
			<fieldset>
				<div className="form-row">

					<div className="form-group col-md-2">
						<label>{translate('Anunciante')}</label>
						<input type="text" className="form-control text-uppercase" placeholder={translate("Anunciante")} name="advertiser" onChange={this.onChange} value={campaignData['advertiser']} required disabled={!campaignData['can_edit']} />
					</div>
					<div className="form-group col-md-2">
						<label>{translate('Produto')}</label>
						<select className="form-control" name="campaign_types_view_id" onChange={this.onChange} value={campaignData['campaign_types_view_id']} disabled={!campaignData['can_edit']}>
							{
								this.props.campaignTypesView.map(campaignTypesView => {
									return (
										<option value={campaignTypesView.campaign_types_view_id} key={campaignTypesView.campaign_types_view_id}>{campaignTypesView.type_campaign}</option>
									)
								})
							}
						</select>
					</div>
					<div className={['1', '5'].indexOf(campaignData['campaign_types_view_id']) === -1 ? "form-group col-md-2" : "d-none"}>
						<label>{translate('Painel')}</label>
						<select className="form-control" name="painel_id" onChange={this.onChange} value={campaignData['painel_id']} disabled={!campaignData['can_edit']}>
							{
								this.props.paineis.map(painel => {
									return (
										<option value={painel.painel_id} key={painel.painel_id}>{painel.description}</option>
									)
								})
							}
						</select>
					</div>
					<div className="form-group col-md-4">
						<label>{translate('Título')}</label>
						<input type="text" className="form-control text-uppercase" placeholder={translate("Título da Pesquisa")} name="name" onChange={this.onChange} value={campaignData['name']} required disabled={!campaignData['can_edit']} />
					</div>
					<div className={['1', '5'].indexOf(campaignData['campaign_types_view_id']) === -1 ? "form-group col-md-2" : "form-group col-md-4"}>
						<label>{translate('Industria')}</label>
						<select className="form-control" name="industry_id" onChange={this.onChange} value={campaignData['industry_id']} disabled={!campaignData['can_edit']}>
							{
								this.props.industries.map(industry => {
									return (
										<option value={industry.industry_id} key={industry.industry_id}>{translate(industry.description)}</option>
									)
								})
							}
						</select>
					</div>
				</div>

				<div className="form-row">

					<div className="form-group col-md-4">
						<label>{translate('Início')}</label>
						<input type="date" className="form-control" name="start" onChange={this.onChange} value={campaignData['start']} required />
					</div>
					<div className="form-group col-md-4">
						<label>{translate('Fim')}</label>
						<input type="date" className="form-control" name="end" onChange={this.onChange} value={campaignData['end']} required />
					</div>
					<div className="form-group col-md-2">
						<label>{translate('Tipo')}</label>
						<select className="form-control" name="campaign_type_id" disabled={!campaignData['can_edit']} onChange={this.onChange} value={campaignData['campaign_type_id']}>
							<option value=''>{translate('Selecione um Tipo')}</option>
							{
								this.props.campaignTypes.map(campaign_type => {
									return (
										<option value={campaign_type.campaign_type_id} key={campaign_type.campaign_type_id}>{campaign_type.description}</option>
									)
								})
							}
						</select>
					</div>

					<div className="form-group col-md-2">
						<label>{translate('Idioma')}</label>
						<select className="form-control" name="language" disabled={!campaignData['can_edit']} onChange={this.onChange} value={campaignData['language']}>
							<option value=''>{translate('Selecione um Idioma')}</option>
							{
								this.props.languages.map(language => {
									return (
										<option value={language} key={language}>{translate(language)}</option>
									)
								})
							}
						</select>
					</div>
				</div>
				{['1', '5'].indexOf(campaignData['campaign_types_view_id']) === -1 ?
					<div className="form-row">
						<div className="col-md-4">
							<label>{translate('Vídeo')}</label>
							<input type="file" className="form-control" name="campaign_video" onChange={this.onChangeFile} />
							{/* <input style={{display: 'block'}} type="file" className="1" placeholder="Logo" name="campaign_video" onChange={this.onChangeFile} /> */}
						</div>
					</div> : null
				}
				<div className="form-row">
					<div className="form-group col-md-2">

						<h4 className="card-title">{translate('Permissionamento')} </h4>
						<label>{translate('Por conta')}:</label>
						<select className="form-control" name="account_id" onChange={this.onChange} value={campaignData['account_id']} onClick={this.escolhaDaCampanha}  >
							<option value='' >{translate('Selecione uma Conta')}</option>
							{
								this.props.accounts.map(account => {
									return (
										<option value={account.account_id} key={account.account_id}>{account.name}</option>
									)
								})
							}
						</select>
						{/* <input type="text" className="form-control text-uppercase" placeholder="Anunciante" name="advertiser" onChange={this.onChange} value={campaignData['advertiser']} required disabled={!campaignData['can_edit']} /> */}

					</div>
				</div>

			</fieldset>
		)
	}
}

const mapStateToProps = store => ({
	campaignData: store.campaign.current,
	campaignTypes: store.campaign.campaign_types,
	industries: store.campaign.industries,
	campaignTypesView: store.campaign.campaign_types_view,
	changeCampaignData: changeCampaignData,
	getCampaignTypesView: getCampaignTypesView,
	getCampaignTypes: getCampaignTypes,
	getIndistries: getIndistries,
	accounts: store.campaign.account,
	paineis: store.campaign.paineis,
	languages: store.campaign.languages,

})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData, getCampaignTypesView, getCampaignTypes, getIndistries, getUserTypesAccount, getPaineis, getLanguages }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)
