import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../../components/Translate';

export class Status extends Component {

	constructor(props) {
		super(props);
		this.user = JSON.parse(localStorage.getItem('user'));
	}

	formata_data = data => {
		return data.split('-').reverse().join('/');
	}

	getStatus = () => {
		// let current_date = new Date();
		// let current_timestamp = Date.parse(current_date.getFullYear()+'-'+(current_date.getMonth()+1).toString().padStart(2, '0')+'-'+current_date.getDate().toString().padStart(2, '0'));
		// let inicio = Date.parse(this.props.infoData.start);
		// let fim = Date.parse(this.props.infoData.end);        
		let status = {
			'Ativo': {
				'classe': 'text-success',
				'label': translate('Ativo')
			}, 'Encerrado': {
				'classe': 'text-danger',
				'label': translate('Encerrado')
			}, 'Futura': {
				'classe': 'text-orange',
				'label': translate('Futura')
			}
		}
		let status_campaigns = this.props.infoData.status_campaigns

		return (<span> <span className={"fa fa-circle " + status[status_campaigns].classe}></span> {status[status_campaigns].label}</span>);

	}

	canEdit = () => {
		let current_date = new Date();
		let current_timestamp = Date.parse(current_date.getFullYear() + '-' + (current_date.getMonth() + 1).toString().padStart(2, '0') + '-' + current_date.getDate().toString().padStart(2, '0'));
		let inicio = Date.parse(this.props.infoData.start);
		let fim = Date.parse(this.props.infoData.end);

		return (current_timestamp >= inicio && current_timestamp <= fim && this.user.group_id === "1" ? //antes == depois ===
			<a href={"/campanhas/edit/" + this.props.infoData.campaign_id} className="btn btn-round btn-sm btn-success">{translate('Editar')}</a>
			: null);
	}

	downloadBtn = () => {
		return (this.user.group_id === "1" && this.props.infoData.campaign_types_view_id != 1 ?
			<a href={"https://ws.brandful.com.br/download/" + this.props.infoData.campaign_id + "/campaignPainelEvents"} className="btn btn-round btn-sm btn-success">{translate('Download Participações')}</a>
			: null)
	}

	render() {
		const { infoData } = this.props;



		return (
			<div className="alert alert-dark pt-1 pb-1">
				<div className="container">
					<div className="d-flex align-items-center justify-content-start small">
						<strong className="mr-1">{translate('Status')}:</strong> {this.getStatus()}

						<div className="pl-4">
							<strong>{translate('Início')}:</strong> <span>{this.formata_data(infoData.start)}</span>
						</div>
						<div className="pl-4">
							<strong>{translate('Término')}:</strong> <span>{this.formata_data(infoData.end)}</span>
						</div>
						<div className="col text-right hidden-print">
							{this.downloadBtn()}
							{this.canEdit()}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current
})
const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Status)
